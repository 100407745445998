import React from "react";

const RequestDemo = () => (
  <section className="global-request-demo platform">
    <div className="container">
      <div className="row">
        <div className="col-lg-12"> 
          <h2>See how Compute Software delivers the deepest cloud cost optimization</h2>
        </div>
        <div className="col-lg-12"> 
          <a className="btn btn-lg request-demo" href="/request-demo" role="button">Request a Demo</a>
        </div> 
      </div>
    </div>
  </section>
);

export default RequestDemo;