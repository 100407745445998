import React from "react"
import Helmet from "react-helmet"
import SEO from "../components/seo"
import Layout from "../components/layout"
import CloudProviders from "../components/global/cloud-providers"
import RequestDemo from "../components/global/request-demo"


import logoAmazonCloudwatch from "../images/logos/logo-amazon-cloudwatch.png"
import logoAzureMonitor from "../images/logos/logo-azuremonitor.png"
import logoGoogleCloudAlt from "../images/logos/logo-gcp.png"
import logoTerraform from "../images/logos/logo-terraform.png"

import logoJira from "../images/logos/logo-jira.png"
import logoDataDog from "../images//logos/logo-datadog.png"
import logoNewRelic from "../images/logos/logo-newrelic.png"
import logoTelegraf from "../images/logos/logo-telegraf.png"

import imgBetterData from "../images/platform/business-criticality.png"
import imgBetterAlgorithms from "../images/platform/dashboard.png"
import imgBetterWorkflow from "../images/platform/workflow.png"

export default function Platform() {
  return (
    <Layout>
      <SEO 
        title="Get the Platform for Cloud Cost Optimization - Compute Software" 
        description="Compute Software's Platform for Cloud Cost Optimization lowers your cloud costs more than any other way. Better data, better algorithms, better workflow."
      />
      <Helmet></Helmet>

      <section className="platform-hero jumbotron">
        <div className="container">
          <h1>Compute Software Cloud Cost Optimization Platform</h1>
          <p>Be 100% Optimal</p>
        </div>
      </section>

      <section className="platform-benefits">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3>The compute software difference</h3>
            </div>
          </div>
          <div className="benefit-wrapper row">
            <div className="col-lg-5">
              <h4>Better <span>Data</span></h4>
              <p>Uses the importance of your workloads (Business Criticality), your workload characteristics (CPU, GPU, memory, storage, network, etc.), and flexible data analysis periods to inform your recommendations.</p>
              <p>Accounts for every enterprise discount, commitment, reservation, sustained use discount, and burstable instance credit to maximize your resource utilization and cost savings.</p>
            </div>
            <div className="separator col-lg-2 hidden-md hidden-sm hidden-xs">
              <div>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d)">
                    <circle cx="28" cy="26" r="19" fill="#F9F9FA"/>
                  </g>
                  <path d="M30.18 19V33H26.94V21.6H24.14V19H30.18Z" fill="#0071E6"/>
                  <defs>
                    <filter id="filter0_d" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                      <feOffset dy="2"/>
                      <feGaussianBlur stdDeviation="4.5"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                  </defs>
                </svg>
              </div>
              <div>
                <svg width="8" height="338" viewBox="0 0 8 338" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="4" cy="4" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="34" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="64" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="94" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="124" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="154" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="184" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="214" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="244" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="274" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="304" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="334" r="4" fill="#E8E8E8"></circle>
                </svg>
              </div>
            </div> 
            <div className="col-lg-5">
              <img src={imgBetterData} alt="Better Data" />
            </div>
          </div>
          <div className="benefit-wrapper row">
            <div className="col-lg-5 hidden-md hidden-sm hidden-xs">
              <img src={imgBetterAlgorithms} alt="Better Algorithms" />
            </div>
            <div className="separator col-lg-2 hidden-md hidden-sm hidden-xs">
              <div>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d)">
                    <circle cx="28" cy="26" r="19" fill="#F9F9FA"/>
                  </g>
                  <path d="M33.24 30.36V33H22.66V30.9L28.06 25.8C28.6333 25.2533 29.02 24.7867 29.22 24.4C29.42 24 29.52 23.6067 29.52 23.22C29.52 22.66 29.3267 22.2333 28.94 21.94C28.5667 21.6333 28.0133 21.48 27.28 21.48C26.6667 21.48 26.1133 21.6 25.62 21.84C25.1267 22.0667 24.7133 22.4133 24.38 22.88L22.02 21.36C22.5667 20.5467 23.32 19.9133 24.28 19.46C25.24 18.9933 26.34 18.76 27.58 18.76C28.62 18.76 29.5267 18.9333 30.3 19.28C31.0867 19.6133 31.6933 20.0933 32.12 20.72C32.56 21.3333 32.78 22.06 32.78 22.9C32.78 23.66 32.62 24.3733 32.3 25.04C31.98 25.7067 31.36 26.4667 30.44 27.32L27.22 30.36H33.24Z" fill="#0071E6"/>
                  <defs>
                    <filter id="filter0_d" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                      <feOffset dy="2"/>
                      <feGaussianBlur stdDeviation="4.5"/>
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                  </defs>
                </svg>
              </div>
              <div>
                <svg width="8" height="338" viewBox="0 0 8 338" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="4" cy="4" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="34" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="64" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="94" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="124" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="154" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="184" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="214" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="244" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="274" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="304" r="4" fill="#E8E8E8"></circle>
                  <circle cx="4" cy="334" r="4" fill="#E8E8E8"></circle>
                </svg>
              </div>
            </div> 
            <div className="col-lg-5">
              <h4>Better <span>Algorithms</span></h4>
              <p>Makes future projections to understand your workloads and resource usage accurately using Machine Learning, expert systems, and predictive analytics.</p>
              <p>Provides metrics and recommendations in terms of cloud provider costs, performance costs, and engineering costs, so you know exactly when and where cost savings tradeoff with performance.</p>
            </div>
            <div className="col-lg-5 visible-md visible-sm visible-xs">
              <img src={imgBetterAlgorithms} alt="Better Algorithms" />
            </div>
          </div>
          <div className="benefit-wrapper row">
            <div className="col-lg-5">
              <h4>Better <span>Workflow</span></h4>
              <p>Prioritizes recommendations by business value and accounts for engineering costs so you can focus only on actions that matter.</p>
              <p>Enables you to validate or invalidate action items through property groups, and track the resolution of actions across teams.</p>
            </div>
            <div className="separator col-lg-2 hidden-md hidden-sm hidden-xs">
              <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                  <circle cx="28" cy="26" r="19" fill="#F9F9FA"/>
                </g>
                <path d="M29.32 24.7C30.5467 24.9 31.4867 25.36 32.14 26.08C32.7933 26.7867 33.12 27.6667 33.12 28.72C33.12 29.5333 32.9067 30.2867 32.48 30.98C32.0533 31.66 31.4 32.2067 30.52 32.62C29.6533 33.0333 28.5867 33.24 27.32 33.24C26.3333 33.24 25.36 33.1133 24.4 32.86C23.4533 32.5933 22.6467 32.22 21.98 31.74L23.24 29.26C23.7733 29.66 24.3867 29.9733 25.08 30.2C25.7867 30.4133 26.5067 30.52 27.24 30.52C28.0533 30.52 28.6933 30.3667 29.16 30.06C29.6267 29.74 29.86 29.2933 29.86 28.72C29.86 27.5733 28.9867 27 27.24 27H25.76V24.86L28.64 21.6H22.68V19H32.48V21.1L29.32 24.7Z" fill="#0071E6"/>
                <defs>
                  <filter id="filter0_d" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="2"/>
                    <feGaussianBlur stdDeviation="4.5"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                  </filter>
                </defs>
              </svg> 
            </div> 
            <div className="col-lg-5">
              <img src={imgBetterWorkflow} alt="Better Workflow" />
            </div>
          </div>
        </div>
      </section>

      <section className="platform-features">
        <div className="container">
          <div className="intro-text row row-flex">
            <div className="col-xs-12 col-sm-12 col-md-12"> 
              <h2>Key Features</h2>
            </div>
          </div>
          <div className="feature-wrapper row row-flex">
            <div className="feature col-xs-12 col-sm-6 col-md-3"> 
              <div className="card">
                <div className="card-body">
                  <svg width="39" height="30" viewBox="0 0 39 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.4438 11.3063C30.3388 4.85625 25.415 0 19.5 0C14.8037 0 10.725 3.075 8.69375 7.55625C3.8025 8.175 0 12.9375 0 18.75C0 21.7337 1.02723 24.5952 2.85571 26.705C4.68419 28.8147 7.16414 30 9.75 30H30.875C31.942 30 32.9985 29.7575 33.9843 29.2864C34.9701 28.8152 35.8658 28.1247 36.6202 27.2541C37.3747 26.3836 37.9732 25.3501 38.3815 24.2127C38.7898 23.0752 39 21.8561 39 20.625C39 15.675 35.6688 11.6625 31.4438 11.3063Z" fill="#0071E6"/>
                  </svg>
                  <h5 className="card-title">Multi-Cloud Support</h5>
                  <p className="card-text">Manage and optimize cloud costs across all three major cloud providers.</p>
                </div>
              </div>
            </div>
            <div className="feature col-xs-12 col-sm-6 col-md-3"> 
              <div className="card">
                <div className="card-body">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.3333 23.3333H20V16.6667H23.3333V23.3333ZM16.6667 23.3333H13.3333V6.66667H16.6667V23.3333ZM10 23.3333H6.66667V11.6667H10V23.3333ZM26.6667 0H3.33333C1.48333 0 0 1.48333 0 3.33333V26.6667C0 27.5507 0.351189 28.3986 0.976311 29.0237C1.60143 29.6488 2.44928 30 3.33333 30H26.6667C27.5507 30 28.3986 29.6488 29.0237 29.0237C29.6488 28.3986 30 27.5507 30 26.6667V3.33333C30 1.48333 28.5 0 26.6667 0Z" fill="#0071E6"/>
                  </svg>
                  <h5 className="card-title">Executive Dashboard</h5>
                  <p className="card-text">Know where you are and where you should be. Get visibility into your provider, performance, and engineering costs.</p>
                </div>
              </div>
            </div>
            <div className="feature col-xs-12 col-sm-6 col-md-3"> 
              <div className="card">
                <div className="card-body">
                  <svg width="33" height="27" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 12.7059H28.5V15.0882H18V12.7059ZM18 8.73529H28.5V11.1176H18V8.73529ZM18 16.6765H28.5V19.0588H18V16.6765ZM30 0H3C2.20435 0 1.44129 0.334663 0.87868 0.930367C0.316071 1.52607 0 2.33402 0 3.17647V23.8235C0 24.666 0.316071 25.4739 0.87868 26.0696C1.44129 26.6653 2.20435 27 3 27H30C30.7956 27 31.5587 26.6653 32.1213 26.0696C32.6839 25.4739 33 24.666 33 23.8235V3.17647C33 2.33402 32.6839 1.52607 32.1213 0.930367C31.5587 0.334663 30.7956 0 30 0ZM30 23.8235H16.5V3.17647H30" fill="#0071E6"/>
                  </svg>
                  <h5 className="card-title">Action Items</h5>
                  <p className="card-text">Get the deepest, most actionable recommendations prioritized by business value.</p>
                </div>
              </div>
            </div>
            <div className="feature col-xs-12 col-sm-6 col-md-3"> 
              <div className="card">
                <div className="card-body">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25.5 13.5H19.5V19.5H16.5V13.5H10.5V10.5H16.5V4.5H19.5V10.5H25.5V13.5ZM27 0H9C8.20435 0 7.44129 0.31607 6.87868 0.87868C6.31607 1.44129 6 2.20435 6 3V21C6 21.7957 6.31607 22.5587 6.87868 23.1213C7.44129 23.6839 8.20435 24 9 24H27C27.7957 24 28.5587 23.6839 29.1213 23.1213C29.6839 22.5587 30 21.7957 30 21V3C30 2.20435 29.6839 1.44129 29.1213 0.87868C28.5587 0.31607 27.7957 0 27 0ZM3 6H0V27C0 27.7957 0.31607 28.5587 0.87868 29.1213C1.44129 29.6839 2.20435 30 3 30H24V27H3V6Z" fill="#0071E6"/>
                  </svg>
                  <h5 className="card-title">Properties</h5>
                  <p className="card-text">Apply your business objectives and workload characteristics to inform your recommendations.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="feature-wrapper row row-flex">
            <div className="feature col-xs-12 col-sm-6 col-md-3"> 
              <div className="card">
                <div className="card-body">
                  <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.02572 18.5455H7.24223V34H1.02572V18.5455ZM11.9046 20.0909H18.1211V34H11.9046V20.0909ZM22.7835 12.3636H29V34H22.7835V12.3636ZM22.7835 0H29V6.18182H27.4459V3.18364L14.9662 15.5936L8.79636 9.45818L1.64737 16.5673L0 14.9291L8.79636 6.18182L14.9662 12.3173L25.7985 1.54545H22.7835V0Z" fill="#0071E6"/>
                  </svg>
                  <h5 className="card-title">Discounts</h5>
                  <p className="card-text">See how your discounts are accounted for to ensure precise tradeoffs.</p>
                </div>
              </div>
            </div>
            <div className="feature col-xs-12 col-sm-6 col-md-3"> 
              <div className="card">
                <div className="card-body">
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.8889 2H2V12.8889H12.8889V2Z" stroke="#0071E6" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M30 2H19.1111V12.8889H30V2Z" stroke="#0071E6" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M30 19.1111H19.1111V30H30V19.1111Z" stroke="#0071E6" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.8889 19.1111H2V30H12.8889V19.1111Z" stroke="#0071E6" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <h5 className="card-title">Simulations</h5>
                  <p className="card-text">Simulate a change before you make it to understand the effect on your cloud costs.</p>
                </div>
              </div>
            </div>
            <div className="feature col-xs-12 col-sm-6 col-md-3"> 
              <div className="card">
                <div className="card-body">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 0C16.9891 0 18.8968 0.790176 20.3033 2.1967C21.7098 3.60322 22.5 5.51088 22.5 7.5C22.5 9.48912 21.7098 11.3968 20.3033 12.8033C18.8968 14.2098 16.9891 15 15 15C13.0109 15 11.1032 14.2098 9.6967 12.8033C8.29018 11.3968 7.5 9.48912 7.5 7.5C7.5 5.51088 8.29018 3.60322 9.6967 2.1967C11.1032 0.790176 13.0109 0 15 0ZM15 18.75C23.2875 18.75 30 22.1063 30 26.25V30H0V26.25C0 22.1063 6.7125 18.75 15 18.75Z" fill="#0071E6"/>
                  </svg>
                  <h5 className="card-title">Workflow Management</h5>
                  <p className="card-text">Validate, resolve, and track actions across your teams and measure business impact over time.</p>
                </div>
              </div>
            </div>
            <div className="feature col-xs-12 col-sm-6 col-md-3"> 
              <div className="card">
                <div className="card-body">
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15C30 6.75 23.25 0 15 0ZM21.3 21.3L13.5 16.5V7.5H15.75V15.3L22.5 19.35L21.3 21.3Z" fill="#0071E6"/>
                  </svg>
                  <h5 className="card-title">Real-Time Automation</h5>
                  <p className="card-text">Choose to turn on automation to aggressively manage your cloud resources and cloud costs in real-time.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="platform-integrations">
        <div className="container">
          <div className="intro-text row row-flex">
            <div className="col-xs-12 col-sm-12 col-md-12"> 
              <h2>Works With These Integrations And More</h2>
              <p>Connect your data, issue tracking tool, and infrastructure configuration tool through <br/>these integrations and others, or use our APIs.</p>
            </div>
          </div>
          <div className="logo-wrapper row row-flex">
            <div className="logo-icon amazon-cloudwatch col-xs-6 col-sm-3 col-md-3"> 
              <img src={logoAmazonCloudwatch} alt="Amazon Cloudwatch Logo" loading="lazy"/>
            </div>
            <div className="logo-icon azure-monitor col-xs-6 col-sm-3 col-md-3"> 
              <img src={logoAzureMonitor} alt="Azure Monitor Logo" loading="lazy"/>
            </div>
            <div className="logo-icon google-cloud col-xs-6 col-sm-3 col-md-3"> 
              <img src={logoGoogleCloudAlt} alt="Google Cloud Logo" loading="lazy"/>
            </div>
            <div className="logo-icon terraform col-xs-6 col-sm-3 col-md-3"> 
              <img src={logoTerraform} alt="Terraform Logo" loading="lazy"/>
            </div>
          </div>
          <div className="logo-wrapper row row-flex">
            <div className="logo-icon jira col-xs-6 col-sm-3 col-md-3"> 
              <img src={logoJira} alt="Jira Logo" loading="lazy"/>
            </div>
            <div className="logo-icon datadog col-xs-6 col-sm-3 col-md-3"> 
              <img src={logoDataDog} alt="DATADOG Logo" loading="lazy"/>
            </div>
            <div className="logo-icon newrelic col-xs-6 col-sm-3 col-md-3"> 
              <img src={logoNewRelic} alt="New Relic Logo" loading="lazy"/>
            </div>
            <div className="logo-icon telegraf col-xs-6 col-sm-3 col-md-3"> 
              <img src={logoTelegraf} alt="Telegraf Logo" loading="lazy"/>
            </div>
          </div>
        </div>
      </section>

      <CloudProviders />

      <RequestDemo />

    </Layout>
  )
}
